<section id="trabajos"  class="section-wrap" style="background-image: url('assets/img/blob-haikei.svg')" aria-label="Imagen onda de fondo" >
  <h2 class="text-center mt-30">NUESTROS TRABAJOS</h2>
    <div class="row mt-40 card-trabajo">
      <swiper-container #swiper id="swiperContainerId" init="false">    
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/caldera_hidrogeno" 
            alt="Hospital universitario de Caceres"
            title="Caldera de Hidrógeno verde" 
            link="/trabajos/caldera-hidrogeno"
            description="Durante el año 2023 Centralgas realizó la instalación interior para conducir el hidrógeno renovable desde el almacenamiento hasta la caldera, siguiendo el reglamento de gases combustibles que se aplica actualmente y los materiales empleados son los mismos que se emplean hoy en día en las viviendas. La sala en la que se ha instalado la caldera servirá de descanso al personal de MRG (Madrileña Red de Gas) demostrando así la viabilidad del uso de hidrógeno en calefacción.">
          </app-card-trabajos>
        </swiper-slide>      
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/hospital_universitario_caceres" 
            alt="Hospital universitario de Caceres"
            title="Hospital universitario de Caceres" 
            description="Realización de la instalación de gas que suministra al Hospital mediante una Estación de Regulación y medida en Alta Presión para 1.000 Nm3/h que alimenta a una sala de calderas dotada de dos equipos YGNIS de 2500 Kw c/u y otro equipo YGNIS de 2000Kw. Se realiza también la instalación para el suministro de gas a la cafetería del hospital para 379 Kw.">
          </app-card-trabajos>
        </swiper-slide>   
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/mandarin_ritz" 
            alt="Mandarín Oriental Ritz"
            title="Mandarín Oriental Ritz" 
            description="Realización de instalación de gas para alimentar a las diversas cocinas del hotel con un total de 23 equipos alimentados por gas natural con una potencia total de 739 Kw, alimentada desde la red de distribución en baja presión. También se realiza la alimentación de la sala de calderas en la cubierta del edificio con un total de 2.451,24 Kw.">
          </app-card-trabajos>
        </swiper-slide>   
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/porsche_madrid" 
            alt="Centro Porsche Madrid Norte"
            title="Centro Porsche Madrid Norte" 
            description="Realización de instalación de gas para las cabinas de pinturas utilizadas en el proceso de secado con un total de 1701 Kw, también se alimenta con gas natural la caldera de 900 Kw así como la cafetería con un total de 120 Kw.">
          </app-card-trabajos>
        </swiper-slide>   
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/campus_airbus" 
            alt="Campus Futura de Airbus"
            title="Campus Futura de Airbus" 
            description="Realización de instalación de gas para las edificios que componen en el Campus Futura de Airbus España ubicado en Getafe con una potencia total instalada de 3155 Kw con alimentación a calderas de gas en cada edificio y a un comedor de empleados.">
          </app-card-trabajos>
        </swiper-slide>  
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/UMusic_hotel" 
            alt="UMusic Hotel Madrid"
            title="UMusic Hotel Madrid" 
            description="Realización de instalación de gas para la cocina y la sala de calderas que alimenta al hotel.">
          </app-card-trabajos>
        </swiper-slide>    
        <swiper-slide>
          <app-card-trabajos
            image="assets/img/Hospital_infantaLeonor" 
            alt="Hospital Infanta Leonor"
            title="Hospital Infanta Leonor" 
            description="Instalación de gas en el hospital Infanta Leonor. Situado en la Avda. de la Democracia Madrid. La instalación receptora se diseña para un consumo de 8382 Kw. Dispone de ERM de 750 Nm3/h de doble línea. Los aparatos de consumo son 5 calderas con quemador mixto de gas/gasoleo. La estación de regulación y medida dispone de un corrector de Presión y Temperatura. El Sistema de seguridad está compuesto por una centralita de detección de 6 zonas.">
          </app-card-trabajos>
        </swiper-slide>
        <swiper-slide>
          <app-card-trabajos 
            image="assets/img/Hospital_SanCarlos" 
            alt="Hospital Clínico San Carlos"
            title="Hospital Clínico San Carlos" 
            description="Instalación de Gas en el Hospital Clínico San Carlos. Situado en la C/ Martín Lagos de Madrid. La instalación se diseña para un consumo de 23000 Kw. La estación de medida tiene un corrector de Presión y Temperatura. La instalación se realiza en doble línea para asegurar el suministro continuo de gas a las calderas.">
          </app-card-trabajos>
        </swiper-slide>
        <swiper-slide>
          <app-card-trabajos 
            image="assets/img/Endesa" 
            alt="Sede Social Endesa"
            title="Sede Social Endesa" 
            description="Instalación de Gas en la Sede Social ENDESA. Situada en la Ribera del Loira, N.º 60 junto a la M-40. La instalación se diseña para un consumo de 3806 Kw. Dispone de ERM para la sala de calderas y la cocina. Los aparatos de consumo son 3 calderas de 1230 Kw cada una.">
          </app-card-trabajos>
        </swiper-slide>
        <swiper-slide>
          <app-card-trabajos 
            image="assets/img/parque-warner" 
            alt="Parque Temático Warner"
            title="Parque Temático Warner" 
            description="Instalación de Gas en el Parque Temático Warner. Situado en San Martín de la Vega, se convierte rápidamente en un centro de ocio de referencia. Sus numerosos restaurantes están dotados de suministro de gas natural y cubren las necesidades de calefacción y agua caliente sanitaria.">
          </app-card-trabajos>
        </swiper-slide>
      </swiper-container>
  </div>
</section>  
