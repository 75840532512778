import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {CookieService} from 'ngx-cookie-service';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ScrollService } from './services/scroll.service';

@Component({
    selector: 'app-root',
    imports: [
        RouterOutlet,
        HeaderComponent,
        FooterComponent,
        ContactoComponent
    ],
    providers: [CookieService],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'CentralGas';

  ser = inject(ScrollService);
}
