<footer>
    <div class="bottom-footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-xs-12 copyright clearfix">
                    <span>
                        © 2024 CentralGas, S.L.
                    </span>
                    <span class="separador">
                        -
                    </span>
                    <span class="privacidad">
                        <a routerLink="/aviso-legal">Aviso legal y política de privacidad</a>
                    </span>
                    <span>
                        |
                    </span>
                    <span class="cookies">
                        <a routerLink="/politica-de-cookies">Política de cookies</a>
                    </span>
                </div>
            </div>
        </div>
    </div> 
    <div id="barraaceptacion">
        <div class="inner texto-barra">
            Solicitamos su permiso para obtener datos estadísticos de su navegación en esta web, en cumplimiento del Real Decreto-ley 13/2012.      
            <a href="javascript:void(0);" class="ok" (click)="onPonerCookie('default')"><b>Aceptar</b></a> | 
            <a routerLink="/politica-de-cookies" class="info"> Más información y Configuración</a>
        </div>
     </div>
    <div id="back-to-top">
        <a href="#top"><i class="fa fa-angle-up"></i></a>
    </div> 
</footer>