import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-card-servicios-icon',
    imports: [CommonModule],
    templateUrl: './card-servicios-icon.component.html',
    styleUrl: './card-servicios-icon.component.css'
})
export class CardServiciosIconComponent {

  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
}
