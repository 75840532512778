
<div class="col-md-9  mb-30 wow fadeInUp card-container">
  <picture>
    <source srcset="{{image}}.webp" type="image/webp" [attr.alt]="title" >
    <source srcset="{{image}}.jpeg" type="image/jpeg" [attr.alt]="title"> 
    <img class="img-fw" loading="lazy" src="{{image}}.jpg" [attr.alt]="title"/>
  </picture>
  <div class="feature-box">
    <p class="title">{{ title }}</p>
    <p class="description"  [innerHTML]="description"></p>
    @if(link){
      <button class="btn btn-sm btn-dark center-block" [routerLink]="link">Ver más</button>
    }
  </div>
</div> 
