<!-- Revolution Slider: Imagen cabecera-->
<app-revolution-slider/>
<!-- end Revolution Slider -->

<!-- La Empresa -->
<app-quienes-somos/>
<!-- end La empresa -->


<!-- Video Section: Socio Iberdrola -->
<app-socio-colaborador/>
<!-- end Video Section -->

<!-- Servicios -->
<app-servicios/>
 <!-- end Servicios -->



<!-- Trabajos segunda opción: Card Logalty... -->
<app-trabajos-card-simple/>
<!-- end Trabajos card Logalty -->

     

<!-- Instalaciones domésticas -->
<app-instalaciones-domesticas/>
<!-- end Instalaciones domésticas -->

