import { Component } from '@angular/core';

@Component({
    selector: 'app-socio-colaborador',
    imports: [],
    templateUrl: './socio-colaborador.component.html',
    styleUrl: './socio-colaborador.component.css'
})
export class SocioColaboradorComponent {

}
