import { Component } from '@angular/core';

@Component({
    selector: 'app-quienes-somos',
    imports: [],
    templateUrl: './quienes-somos.component.html',
    styleUrl: './quienes-somos.component.css'
})
export class QuienesSomosComponent {

}
