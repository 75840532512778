import { Component, OnInit } from '@angular/core';
import { RevolutionSliderComponent } from '../../components/revolution-slider/revolution-slider.component';
import { QuienesSomosComponent } from '../../components/quienes-somos/quienes-somos.component';
import { SocioColaboradorComponent } from '../../components/socio-colaborador/socio-colaborador.component';
import { TrabajosCardSimpleComponent } from '../../components/trabajos-card-simple/trabajos-card-simple.component';
import { ServiciosComponent } from '../../components/servicios/servicios.component';
import { InstalacionesDomesticasComponent } from '../../components/instalaciones-domesticas/instalaciones-domesticas.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    imports: [
        RevolutionSliderComponent,
        QuienesSomosComponent,
        SocioColaboradorComponent,
        TrabajosCardSimpleComponent,
        ServiciosComponent,
        InstalacionesDomesticasComponent
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit { 
  
  constructor(private readonly meta: Meta, private readonly title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Soluciones Eficientes en Suministro de Gas y Energía - Centralgas');
    this.meta.addTag({ name: 'description', content: 'Descubre soluciones eficientes y sostenibles de suministro de gas para hogares y empresas con Centralgas del grupo Sunflower Energías. Garantizamos energía limpia, segura y asequible, con un compromiso firme hacia el medio ambiente y la innovación.' });
  }
}
