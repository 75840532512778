import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AvisoLegalComponent } from '../aviso-legal/aviso-legal.component';
import { PoliticaDeCookiesComponent } from '../politica-de-cookies/politica-de-cookies.component';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-cabecera-politicas',
    imports: [
        CommonModule,
        AvisoLegalComponent,
        PoliticaDeCookiesComponent
    ],
    templateUrl: './cabecera-politicas.component.html',
    styleUrl: './cabecera-politicas.component.css'
})
export class CabeceraPoliticasComponent implements OnInit{
  currentRoute: string = '';

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly title: Title) {}



  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.currentRoute = this.router.url.replace('/','');

      if (this.currentRoute === 'aviso-legal') {
        this.title.setTitle('Aviso Legal - Centralgas');
      } else if (this.currentRoute === 'politica-de-cookies') {
        this.title.setTitle('Política de Cookies - Centralgas');
      }

    });
  }
  
}
