import { Component } from '@angular/core';

@Component({
    selector: 'app-instalaciones-domesticas',
    imports: [],
    templateUrl: './instalaciones-domesticas.component.html',
    styleUrl: './instalaciones-domesticas.component.css'
})
export class InstalacionesDomesticasComponent {

}
