import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CookiesService } from '../../services/cookies.service';

@Component({
    selector: 'app-footer',
    imports: [RouterModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.css'
})
export class FooterComponent {

  constructor(private cookiesServiceManual : CookiesService) {}
  ngOnInit(): void {
    const chkPersonalizacion = document.getElementById("chkCPState") as HTMLInputElement;
    const chkAnalitica = document.getElementById("chkCAState") as HTMLInputElement;
  

   

    // Si no se aceptaron las cookies personalizadas, mostramos la barra de aceptación
    if (!this.cookiesServiceManual.personalizacionAceptada()) {
      this.cookiesServiceManual.mostrarBarraAceptacion(true);
    }

  }

 
  onPonerCookie(tipo: string = 'default'): void {
    this.cookiesServiceManual.ponerCookie(tipo);
  }
}
