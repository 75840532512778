<div class="container">

    <div class="blog-content"><!-- standard post -->
        <div class="entry-item mb-0">
            <div class="entry-img"><img src="assets/img/caldera_hidrogeno.jpg" alt=""></div>
            <div class="entry">
                <h1 class="entry-title text-center">Centralgas realiza la instalación de la primera caldera de Hidrógeno verde en
                    Madrid.</h1>
                <div class="entry-content">
                    <h3 class="text-center"> Mucho trabajo, compromiso e involucración de todas las personas que
                        componen el proyecto, han sido la clave de nuestro éxito. </h3>
                    <p>
                        Durante el año 2023 Centralgas realizó la instalación interior para conducir el hidrógeno
                        renovable desde el almacenamiento hasta la caldera, siguiendo el reglamento de gases
                        combustibles que se aplica actualmente y los materiales empleados son los mismos que se emplean
                        hoy en día en las viviendas. La sala en la que se ha instalado la caldera servirá de descanso al
                        personal de MRG (Madrileña Red de Gas) demostrando así la viabilidad del uso de hidrógeno en
                        calefacción.
                    </p>
                    <p>La caldera es un prototipo cedido por Vaillant, homologado en España, y preparado para la
                        utilización 100% H2. Con una potencia de 25 kW es equivalente en forma y tamaño a una caldera
                        actual de gas natural.</p>

                    <p>El hidrógeno es una alternativa limpia e inagotable al uso de combustibles fósiles. El hidrógeno
                        se puede emplear, por ejemplo, para producir calefacción y agua caliente con una caldera similar
                        a las actuales de gas natural. La principal ventaja del hidrógeno es que, en su combustión, no
                        libera elementos contaminantes a la atmósfera, únicamente vapor de agua, colaborando activamente
                        en el objetivo de descarbonización. Aunque todavía no es una realidad al alcance de todos los
                        usuarios, esta pequeña instalación es un gran paso para la sociedad, marcando la viabilidad de
                        su instalación y funcionamiento.</p>

                    <p>De esta forma, Centralgas se coloca en la vanguardia de las empresas instaladoras y mantenedoras
                        en la instalación de calderas de hidrógeno verde, ampliando así su gama de servicios en el
                        sector residencial, terciario e industrial.</p>

                    <p>Actualmente Centralgas se encuentra desarrollando proyectos importantes que en breve verán la
                        luz, aumentando su experiencia y compromiso con el medio ambiente y la eficiencia de las
                        instalaciones.</p>

                        Fuente:
                        <a href="https://www.madrilena.es/en-marcha-la-primera-caldera-de-hidrogeno-verde-en-madrid-para-generar-calefaccion-sostenible/" target="_blank">https://www.madrilena.es/en-marcha-la-primera-caldera-de-hidrogeno-verde-en-madrid-para-generar-calefaccion-sostenible/</a>



                </div>
            </div>
        </div>
    </div>

</div>