<section id="contacto" class="section-wrap pb-20">
    <div class="container">
        <h2 class="text-center titulo bottom-line mb-30">CONTACTO</h2>
        <div class="footer-widgets pb-60">
            <div class="row">
                <div class="col-md-6 footer-get-in-touch contacto">
                    <h6>Téfono: 914303867</h6>
                    <h6>Email: <a href="mailto:centralgas&#64;centralgas.es" id="linkmail">centralgas&#64;centralgas.es</a></h6>
                    <div>
                        <app-ubicacion-mapa/>
                    </div> 
                </div>
                <div class="col-md-6 col-12 footer-get-in-touch">
                    <app-card-contacto 
                    title="Contacta con nosotros">
                    <form [formGroup]="formularioContacto" (ngSubmit)="onSubmit()">
                        <div [ngClass]="{'has-error': checkError('nombre', 'required') || checkError('nombre', 'maxlength')}">
                            <div class="diverror">
                                <label for="nombre" class="form-label">Nombre</label>
                                <p class="error text-danger mb-0 ms-2" *ngIf="checkError('nombre', 'required')">
                                    El campo nombre es requerido
                                </p>
                                <p class="error" *ngIf="checkError('nombre', 'maxlength')">
                                    Longitud máxima  100
                                </p> 
                            </div>
                            <input id="nombre" type="text" class="form-control" formControlName="nombre">
                        </div>
                        
                       <div [ngClass]="{'has-error': checkError('apellidos', 'required') || checkError('apellidos', 'maxlength')}">
                        <div class="diverror">
                            <label for="apellidos" class="form-label">Apellidos</label>
                            <p class="error" *ngIf="checkError('apellidos', 'required')">
                                El campo apellidos es requerido
                            </p> 
                            <p class="error" *ngIf="checkError('apellidos', 'maxlength')">
                                Longitud máxima  100
                            </p> 
                        </div>
                            <input id="apellidos" type="text" class="form-control" formControlName="apellidos">
                         
                        </div>
            
                       <div [ngClass]="{'has-error': checkError('email', 'required') || checkError('email', 'pattern')}">
                        <div class="diverror">
                            <label for="email" class="form-label">Email</label>
                            <p class="error" *ngIf="checkError('email', 'pattern')">
                                El campo email tiene formato incorrecto
                            </p> 
                            <p class="error" *ngIf="checkError('email', 'required')">
                                El email es requerido
                            </p> 
                            </div>
                            <input id="email" type="email" class="form-control" formControlName="email">
                         
                        </div>
            
                       <div [ngClass]="{'has-error': checkError('telefono', 'required') || checkError('telefono', 'pattern')}">
                        <div class="diverror">
                            <label for="telefono" class="form-label">Teléfono de contacto</label>
                                <p class="error" *ngIf="checkError('telefono', 'pattern')">
                                    El teléfono tiene formato incorrecto
                                </p> 
                                <p class="error" *ngIf="checkError('telefono', 'required')">
                                    El teléfono es requerido
                                </p> 
                            </div>
                            <input id="telefono" type="text" class="form-control" formControlName="telefono">
                          
                        </div>
            
                       <div [ngClass]="{'has-error': checkError('mensaje', 'required') || checkError('mensaje', 'maxlength')}">
                        <div class="diverror">
                            <label for="mensaje" class="form-label">Mensaje</label>
                            <p class="error" *ngIf="checkError('mensaje', 'required')">
                                Debe escribir un mensaje
                            </p> 
                            <p class="error" *ngIf="checkError('mensaje', 'maxlength')">
                                Longitud máxima  100
                            </p> 
                        </div>
                            <textarea id="mensaje" class="form-control" formControlName="mensaje"></textarea>                           
                        </div> 
                        
                  
                        
                        <div class="form-group div-form-check">
                            <input 
                                type="checkbox" 
                                class="form-check-input" 
                                id="terminos" 
                                formControlName="terminos">
                            <label for="terminos" class="form-check-label ml-10" >
                                     He leído y acepto los 
                                <a href="javascript:void(0);" class="terminos-enlace" (click)="toggleTerminos()">términos y condiciones</a> 
                            </label>
                        </div>
                    
                        <div *ngIf="mostrarTerminos" class="mt-10 mb-10">
                            <hr />
                            <h6>Información Básica sobre Protección de datos</h6>
                            <p><strong>Responsable:</strong> CENTRAL GAS S.L. </p>
                            <p><strong>Finalidad:</strong> Atender su solicitud.</p>
                            <p><strong>Legitimación:</strong> Consentimiento del interesado.</p>
                            <p><strong>Destinatarios:</strong> No se cederán datos a terceros, salvo obligación legal.</p>
                            <p><strong>Derechos:</strong> Tiene derecho a Acceder, Rectificar, Suprimir, Limitación del tratamiento, Portabilidad de los datos, Oposición, retirar su consentimiento en cualquier momento, contactando con nosotros: centralgas&#64;centralgas.es</p>
                       
                            <a routerLink="/aviso-legal" target="_blank" class="terminos-enlace"><strong>Más información</strong></a>
                            <hr />
                        </div>
                    
                        

                        <div class="form-group">
                            <re-captcha formControlName="recaptchaReactive" siteKey="6LcQQVoqAAAAAEM_okke6LmtlQxv5bCgtj7JO7Ju"></re-captcha>
                        </div>


                        @if (!sendingContacto && !this.contacto.status) {
                            <button type="submit" id="btnEnviarContacto" class="btn btn-sm btn-dark center-block" [disabled]="!formularioContacto.valid">Enviar<span class="fa fa-paper-plane-o"></span></button>
                        }
                        @else if(sendingContacto && !this.contacto.status) {
                            <button type="submit" id="btnEnviarContacto" class="btn btn-sm btn-dark center-block" disabled>Enviando...<span class="fa fa-paper-plane-o"></span></button>
                        }
                        
                        
                        @if(contacto.status !== undefined){
                            <div id="alertaEstado" class="mt-10 alert" [class.alert-success]="contacto.status" [class.alert-danger]="!contacto.status">{{contacto.message}}</div>
                        }                        
                    </form>
                   </app-card-contacto>
                  
                </div>
            </div>
         
        </div>
    </div>
</section>

