import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private cookieService: CookieService) {  }

    /**
   * Obtiene el valor de una cookie.
   * @param c_name Nombre de la cookie
   */
    getCookie(c_name: string): string | null {
      return this.cookieService.get(c_name) || null;
    }
  
    /**
     * Establece una cookie con un nombre, valor y días de expiración.
     * @param c_name Nombre de la cookie
     * @param value Valor de la cookie
     * @param exdays Días de expiración
     */
    setCookie(c_name: string, value: string, exdays: number): void {
      const host = window.location.hostname.replace('www.', '');
      const exdate = new Date();
      exdate.setDate(exdate.getDate() + exdays);
      this.cookieService.set(c_name, value, exdays, '/', host);
    }
  
    /**
     * Borra una cookie estableciendo su valor vacío y su fecha de expiración en el pasado.
     * @param c_name Nombre de la cookie
     */
    delCookie(c_name: string): void {
      this.cookieService.delete(c_name, '/');
    }
  
    /**
     * Coloca las cookies según el tipo solicitado.
     * @param tipo Tipo de cookie: "analiticas" o "personalizacion"
     */
    ponerCookie(tipo: string): void {
      switch (tipo) {
        case 'analiticas':
          this.iniciarGoogleAnalytics();
          break;
        case 'personalizacion':
          this.setCookie('cookies', '1', 365);
          this.mostrarBarraAceptacion(false);
          break;
        default:
          this.setCookie('cookies', '1', 365);
          this.mostrarBarraAceptacion(false);
          this.iniciarGoogleAnalytics();
          break;
      }
    }
  
    /**
     * Inicia Google Analytics si se han aceptado las cookies analíticas.
     */
  
    iniciarGoogleAnalytics(): void {
    
      this.setCookie('_ga', 'GA1.2.123456789.987654321', 365); 
      this.setCookie('_gat', '1', 365);                         
      this.setCookie('_gid', 'GA1.2.123456789.987654321', 365); 
    
    }

    
    /**
     * Muestra u oculta la barra de aceptación de cookies.
     * @param mostrar Booleano para indicar si se debe mostrar o no
     */
    mostrarBarraAceptacion(mostrar: boolean): void {
      const barraAceptacion = document.getElementById('barraaceptacion');
      if (barraAceptacion) {
        barraAceptacion.style.display = mostrar ? 'block' : 'none';
      }
    }
  
    /**
     * Evalúa si las cookies de personalización han sido aceptadas.
     */
    personalizacionAceptada(): boolean {
      return this.getCookie('cookies') === '1';
    }
  
    /**
     * Evalúa si las cookies analíticas han sido aceptadas.
     */
    analiticaAceptada(): boolean {
      return !!this.getCookie('_ga') || !!this.getCookie('_gat') || !!this.getCookie('_gid');
    }
  
    /**
     * Borra las cookies analíticas.
     */
    delAnaliticas(): void {
      ['_ga', '_gat', '_gid'].forEach((cookie) => this.delCookie(cookie));
    }
  
    /**
     * Cambia el estado de las cookies de personalización basado en el checkbox.
     */
    personalizacionStateChange(event: Event): void {
      const checkbox = event.target as HTMLInputElement;
      if (checkbox.checked) {
        this.ponerCookie('personalizacion');
      } else {
        this.delCookie('cookies');
        this.mostrarBarraAceptacion(true);
      }
    }
  
    /**
     * Cambia el estado de las cookies analíticas basado en el checkbox.
     */
    analiticaStateChange(event: Event): void {
      const checkbox = event.target as HTMLInputElement;
      if (checkbox.checked) {
        this.ponerCookie('analiticas');
      } else {
        this.delAnaliticas();
      }
    }
}
