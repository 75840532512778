import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UbicacionMapaComponent } from '../ubicacion-mapa/ubicacion-mapa.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CardContactoComponent } from '../card-contacto/card-contacto.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha-2';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-contacto',
    imports: [
        CommonModule,
        UbicacionMapaComponent,
        ReactiveFormsModule,
        CardContactoComponent,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    templateUrl: './contacto.component.html',
    styleUrl: './contacto.component.css'
})
export class ContactoComponent {
  formularioContacto: FormGroup;
  mostrarTerminos: boolean = false;

  contacto: {status?: boolean, message?: string} = {
    status: undefined,
    message: undefined,
  };
  sendingContacto: boolean = false;

  constructor(private readonly http: HttpClient){
    this.formularioContacto = new FormGroup({
      nombre: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(100)])),
      apellidos: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(100)])),
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ]),
      telefono: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^[9|8|6|7](\d{2}-*){3}\d{2}$/)])),
      mensaje: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(5000)])),
      terminos: new FormControl(false, [
        Validators.requiredTrue
      ]),  
      recaptchaReactive: new FormControl(null, [
        Validators.required
      ])
    }, [
    ]);
  }

  checkError(controlName: string, validator: string) {
    return this.formularioContacto.get(controlName)?.hasError(validator) && this.formularioContacto.get(controlName)?.touched;
  }


  toggleTerminos() {
    this.mostrarTerminos = !this.mostrarTerminos; 
  }


  onSubmit() {
    if (this.formularioContacto.valid ) {
      this.sendingContacto = true;
      this.http.post('./contacto', this.formularioContacto.getRawValue() ).subscribe({
        next: (response: any) => {
          if(response.status){
            this.sendingContacto = false;
            this.contacto = {
              status: true,
              message: response.message
            };
          }
          else{
            this.sendingContacto = false;
            this.contacto = {
              status: false,
              message: response.message
            };
          }
        },
        error: (err) => {
          this.sendingContacto = false;
        }
      });       
    } else {
      console.log( 'Formulario no válido');
    }
  }
}

