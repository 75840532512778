import { Component } from '@angular/core';

@Component({
    selector: 'app-ubicacion-mapa',
    imports: [],
    templateUrl: './ubicacion-mapa.component.html',
    styleUrl: './ubicacion-mapa.component.css'
})
export class UbicacionMapaComponent {

}
