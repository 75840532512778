import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CabeceraPoliticasComponent } from './pages/cabecera-politicas/cabecera-politicas.component';
import { CalderaHidrogenoComponent } from './pages/trabajos/caldera-hidrogeno/caldera-hidrogeno.component';
import { AppComponent } from './app.component';


export const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'aviso-legal', component: CabeceraPoliticasComponent },
    { path: 'politica-de-cookies', component: CabeceraPoliticasComponent },
    { path: 'trabajos/caldera-hidrogeno', component: CalderaHidrogenoComponent }

  
];
