import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-card-trabajos',
    imports: [RouterModule],
    templateUrl: './card-trabajos.component.html',
    styleUrl: './card-trabajos.component.css'
})
export class CardTrabajosComponent {
  @Input() image: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() link: string = '';
}
