<!-- Navigation -->
    <header class="nav-type-2">
        <nav class="navbar navbar-static-top">
            <div class="navigation">
                <div class="container-fluid relative">                   
                    <div class="row navbar-header">
                        <div class="col navbar-header-toggle">
                           <!-- Logo -->
                           <div class="logo-container">
                                <div class="logo-wrap local-scroll">
                                    <a routerLink="/">
                                        <picture>
                                            <source srcset="assets/img/logoCentralGas.webp" type="image/webp" alt="Logo CentralGas">
                                            <source srcset="assets/img/logoCentralGas.png" type="image/png" alt="Logo CentralGas"> 
                                            <img src="assets/img/logoCentralGas.png" alt="Logo CentralGas"/>
                                        </picture>
                                    </a>
                                </div>
                                
                                <div class="logo-wrap local-scroll-iberdrola">
                                    <a href="https://www.iberdrola.es" target="_blank" rel="noopener noreferrer">
                                        <picture>
                                            <source srcset="assets/img/socioColaborador-iberdrola.webp" type="image/webp" alt="Colaborador Iberdrola">
                                            <source srcset="assets/img/socioColaborador-iberdrola.png" type="image/png" alt="Colaborador Iberdrola"> 
                                            <img src="assets/img/socioColaborador-iberdrola.png" alt="Colaborador Iberdrola"/>
                                        </picture>
                                    </a>
                                </div>
                            </div>   

                                <div class="toggle-container d-none d-md-block d-xl-none">
                                <button type="button" class="navbar-toggle"   (click)="toggleDropdown()">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                </button>
                            </div>
                        </div>
                        <!-- end navbar-header -->

                        <div class="col-md-12 nav-wrap text-md-end"
                        [ngClass]="{'nav-hidden': !isMenuOpen}">
                             <div [ngClass]="{'d-md-flex': true, 'd-none': isMenuOpen, 'mostrar-toggle': !isMenuOpen}" id="navbar-collapse"> 

                                <ul class="nav navbar-nav">
                                    <li class="quienesSomos">
                                        <a [routerLink]="['']" [fragment]="'quienesSomos'"> ¿Quiénes somos?</a>
                                    </li>
                                    
                                    <li class="servicios">
                                        <a [routerLink]="['']" [fragment]="'servicios'">Servicios</a>
                                    </li>

                                    <li class="trabajos">
                                        <a [routerLink]="['']" [fragment]="'trabajos'"> Trabajos</a>
                                    </li>
                                        
                                    <li class="instalacionesDomesticas">
                                        <a [routerLink]="['']" [fragment]="'instalacionesDomesticas'"> Instalaciones Domésticas</a>
                                    </li>

                                    <li class="contacto">
                                        <a [routerLink]="['']" [fragment]="'contacto'">Contacto</a>
                                    </li>
                                    <li>
                                        <a href="https://www.sunflowerenergias.es" target="_blank" rel="noopener noreferrer">
                                            <picture>
                                                <source srcset="assets/img/logoSunflower.webp" type="image/webp" alt="Logo CentralGas">
                                                <source srcset="assets/img/logoSunflower.png" type="image/png" alt="Logo CentralGas"> 
                                                <img src="assets/img/logoSunflower.png" alt="Logo Sunflower"/>
                                            </picture>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                     
                        <!-- end col -->
                    </div>
                    <!-- end row -->
                </div>
                <!-- end container -->
            </div>
            <!-- end navigation -->
        </nav>
        <!-- end navbar -->
    </header>
<!-- end navigation -->