import { Component } from '@angular/core';

@Component({
  selector: 'app-caldera-hidrogeno',
  imports: [],
  templateUrl: './caldera-hidrogeno.component.html',
  styleUrl: './caldera-hidrogeno.component.css'
})
export class CalderaHidrogenoComponent {

}
