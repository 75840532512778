import { 
  Component, 
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { CardTrabajosComponent } from '../card-trabajos/card-trabajos.component';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element/bundle';

@Component({
    selector: 'app-trabajos-card-simple',
    imports: [
        CardTrabajosComponent
    ],
    templateUrl: './trabajos-card-simple.component.html',
    styleUrl: './trabajos-card-simple.component.css',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TrabajosCardSimpleComponent  implements AfterViewInit {
  @ViewChild('swiper') swiperRef!: ElementRef<SwiperContainer>;
  //navigation="true" slides-per-view="4"   free-mode="true"  grab-cursor="true"

  
  ngAfterViewInit(): void {

      const swiperParams: SwiperOptions = {
        slidesPerView: 1,
        navigation: true, 
        grabCursor: true,
        freeMode: false, 
        spaceBetween: 10, 
        breakpoints: {
          320: {
            slidesPerView: 1,
            freeMode: false,
            loop: true
          },
          640: {
            slidesPerView: 2,
            freeMode: true,
            loop: false
          },
          1040: {
            slidesPerView: 3,
            freeMode: true,
            loop: false
          },
          1870: {
            slidesPerView: 4,
            freeMode: true,
            loop: false
          }
        }
      };

      Object.assign(this.swiperRef.nativeElement, swiperParams);


      //this.swiperRef.nativeElement.slidesPerView = 3;
      (<any>this.swiperRef.nativeElement).initialize();
    
  }
}
