import { Injectable } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute  } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollService {
  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {   
    this.router.events.subscribe((event) => {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        // Restaurar posición de scroll en cada navegación
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Manejar scroll a fragmentos (#id)
        const fragment = this.activatedRoute.snapshot.fragment;
        if (fragment) {
          setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 100); // Pequeño delay para asegurar que el DOM esté listo
        }
      });
    });
  }
}
