import { Component, OnInit } from '@angular/core';
import { PoliticaDeCookiesComponent } from '../politica-de-cookies/politica-de-cookies.component';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-aviso-legal',
    imports: [
        PoliticaDeCookiesComponent
    ],
    templateUrl: './aviso-legal.component.html',
    styleUrl: './aviso-legal.component.css'
})
export class AvisoLegalComponent implements OnInit { 
  

  constructor(private readonly meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: 'Consulta el Aviso Legal de Centralgas del grupo Sunflower Energías. Conoce la información legal sobre el uso del sitio web, derechos de autor, protección de datos y condiciones de servicio.' });
  }
}

  


